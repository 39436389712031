export const palette = {
  white: '#FFFFFF',
  black: '#000000',

  gray25: '#2c3235',
  gray15: '#22252b', //'#202226',
  gray10: '#181b1f', // old '#141619',
  gray05: '#111217', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#18181A',
  darkLayer1: '#212124',
  darkLayer2: '#2a2a2f', // figma used #34343B but a bit too bright

  darkBorder1: '#34343B',
  darkBorder2: '#64646B',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#F4F5F5',
  // Card bg / layer 1
  gray100: '#F4F5F5',
  // divider line
  gray80: '#D0D1D3',
  // from figma
  lightBorder1: '#E4E7E7',

  blueDarkMain: '#2F54EB', // '#4165F5',
  blueDarkText: '#5D7AEF', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#FF4D4F',
  redDarkText: '#FF4D4F',
  greenDarkMain: '#2F54EB',
  greenDarkText: '#5D7AEF',
  orangeDarkMain: '#FF4D4F',
  orangeDarkText: '#FF4D4F',

  blueLightMain: '#2F54EB',
  blueLightText: '#213BA2',
  redLightMain: '#FF4D4F',
  redLightText: '#BB373D',
  greenLightMain: '#2F54EB',
  greenLightText: '#213BA2',
  orangeLightMain: '#FF4D4F',
  orangeLightText: '#BB373D',
};
